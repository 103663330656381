import { errorToMessage } from '../../../errorMessaging';
import STATUS from '../../globalStatuses';
import {
  ACTIONS,
  RECEIVE_NAME,
  RECEIVE_NAMES,
  RECEIVE_PERSON,
  RECEIVE_PERSON_INFO,
  RECEIVE_POST_NAME,
  RECEIVE_POST_PERSON,
  RECEIVE_POST_PERSON_INFO,
  RECEIVE_POST_PREVIOUS_NAME,
  RECEIVE_POST_PREVIOUS_NAME_ERROR,
  RECEIVE_START_WORKFLOW,
  RECEIVE_UPDATE_PERSON,
  REQUEST_NAME,
  REQUEST_NAMES,
  REQUEST_NAMES_ERROR,
  REQUEST_NAME_ERROR,
  REQUEST_NAME_POST_ERROR,
  REQUEST_PERSON,
  REQUEST_PERSON_ERROR,
  REQUEST_PERSON_INFO,
  REQUEST_PERSON_INFO_ERROR,
  REQUEST_PERSON_INFO_RESET,
  REQUEST_PERSON_META,
  RECEIVE_PERSON_META,
  REQUEST_PERSON_META_ERROR,
  REQUEST_POST_NAME,
  REQUEST_POST_PERSON,
  RECEIVE_OFFICIAL_NAME_ETAG,
  RECEIVE_PREVIOUS_NAME_ETAG,
  REQUEST_POST_PERSON_ERROR,
  REQUEST_POST_PERSON_INFO,
  REQUEST_PERSON_INFO_MISSING,
  REQUEST_POST_PERSON_INFO_ERROR,
  REQUEST_POST_PREVIOUS_NAME,
  REQUEST_START_WORKFLOW,
  REQUEST_START_WORKFLOW_ERROR,
  REQUEST_UPDATE_PERSON,
  REQUEST_UPDATE_PERSON_ERROR,
  REQUEST_DELETE_NAME,
  RECEIVE_DELETE_NAME,
  RECEIVE_DELETE_NAME_ERROR,
  MULTISTEP_CREATE_ACCOUNT_START,
  MULTISTEP_CREATE_ACCOUNT_COMPLETION,
  MULTISTEP_CREATE_ACCOUNT_ERROR,
  REQUEST_POST_PERSON_GENERIC_INFO,
  RECEIVE_POST_PERSON_GENERIC_INFO,
  RECEIVE_PUT_PERSON_GENERIC_INFO,
  REQUEST_PUT_PERSON_GENERIC_INFO,
  REQUEST_POST_PERSON_GENERIC_INFO_ERROR,
  REQUEST_PUT_PERSON_GENERIC_INFO_ERROR,
  REQUEST_PERSON_GENERIC_INFO,
  RECEIVE_PERSON_GENERIC_INFO,
  REQUEST_PERSON_GENERIC_INFO_MISSING,
  REQUEST_PERSON_GENERIC_INFO_ERROR,
  CLEAR_PERSON_GENERIC_INFO_GET_STATUS,
} from './types';

function person(
  state = {
    person: {},
  },
  action,
) {
  switch (action.type) {
    // GET Person
    case REQUEST_PERSON:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON:
      return {
        ...state,
        person: action.person,
        personETag: action.personETag,
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personStatus: {
          ...state.personStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST Person
    case REQUEST_POST_PERSON:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case REQUEST_POST_PERSON_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.ERROR,
        },
      };
    case RECEIVE_POST_PERSON:
      return {
        ...state,
        person: action.person,
        personETag: action.personETag,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATED,
          errorData: action.error,
        },
        names: [
          {
            personId: action.person.id || action.person.personId,
            firstName: action.person.firstName,
            lastName: action.person.lastName,
          },
        ],
      };

    case ACTIONS.CLEAR_NAME_MODIFY_STATUS:
      return {
        ...state,
        nameStatus: {
          ...state.person.nameStatus,
          modifyStatus: STATUS.UNFETCHED,
        },
      };

    // PUT Person
    case REQUEST_UPDATE_PERSON:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_UPDATE_PERSON:
      return {
        ...state,
        person: action.person,
        personETag: action.personETag,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_UPDATE_PERSON_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Name
    case REQUEST_NAME:
      return {
        ...state,
        nameStatus: {
          ...state.nameStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_NAME:
      return {
        ...state,
        name: action.name,
        nameETag: action.nameETag,
        nameStatus: {
          ...state.nameStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_NAME_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        nameStatus: {
          ...state.nameStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Person Names
    case REQUEST_NAMES:
      return {
        ...state,
        namesStatus: {
          ...state.namesStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_NAMES:
      return {
        ...state,
        names: action.names,
        //     personETag: action.personETag,
        namesStatus: {
          ...state.namesStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_NAMES_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        namesStatus: {
          ...state.namesStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case RECEIVE_OFFICIAL_NAME_ETAG:
      return {
        ...state,
        officialNameETag: action.nameETag,
        officialNameStatus: STATUS.FETCHED,
      };
    case RECEIVE_PREVIOUS_NAME_ETAG:
      return {
        ...state,
        previousNameETag: action.nameETag,
        previousNameStatus: STATUS.FETCHED,
      };
    // POST Names
    case REQUEST_POST_NAME:
      return {
        ...state,
        namesStatus: {
          ...state.namesStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_NAME:
      switch (action.name.type.toLowerCase()) {
        case 'official':
          return {
            ...state,
            namesETag: action.namesETag,
            namesStatus: {
              ...state.namesStatus,
              getStatus: STATUS.FETCHED,
              modifyStatus: STATUS.UPDATED,
            },
            names: {
              ...state.names,
              officialName: action.name,
            },
            name: action.name,
          };
        case 'previous':
          return {
            ...state,
            namesETag: action.namesETag,
            names: {
              ...state.names,
              previousName: action.name,
            },
            namesStatus: {
              ...state.namesStatus,
              modifyStatus: STATUS.UPDATED,
            },
          };
        default:
          return state;
      }
    case REQUEST_NAME_POST_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        namesStatus: {
          ...state.namesStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE Names
    case REQUEST_DELETE_NAME:
      return {
        ...state,
        namesStatus: {
          ...state.namesStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_DELETE_NAME:
      if (state.names.previousName && state.names.previousName.id === action.nameId) {
        const newState = {
          ...state,
          names: {
            ...state.names,
            previousName: null,
          },
          namesStatus: {
            ...state.namesStatus,
            modifyStatus: STATUS.UPDATED,
          },
        };
        return newState;
      }
      return {
        ...state,
        namesStatus: {
          ...state.namesStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case RECEIVE_DELETE_NAME_ERROR:
      return {
        ...state,
        error: action.error,
        namesStatus: {
          ...state.namesStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST Prev Names
    case REQUEST_POST_PREVIOUS_NAME:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PREVIOUS_NAME:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case RECEIVE_POST_PREVIOUS_NAME_ERROR:
      return {
        ...state,
        personStatus: {
          ...state.personStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Employments
    case ACTIONS.REQUEST_EMPLOYMENTS:
      return {
        ...state,
        employmentsStatus: {
          ...state.employmentsStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
        employments: null,
      };
    case ACTIONS.RECEIVE_EMPLOYMENTS:
      return {
        ...state,
        employments: action.employments,
        curEmployments: action.curEmployments,
        prevEmployments: action.prevEmployments,
        workLikeEmployments: action.workLikeEmployments,
        employmentsStatus: {
          ...state.employmentsStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_EMPLOYMENTS_ERROR:
      return {
        ...state,
        error: action.error,
        employmentsStatus: {
          ...state.employmentsStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case ACTIONS.CLEAR_EMPLOYMENTS_GET_STATUS:
      return {
        ...state,
        employmentsStatus: {
          ...state.employmentsStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };

    // POST Employments
    case ACTIONS.REQUEST_SAVE_EMPLOYMENTS:
      return {
        ...state,
        employmentsStatus: {
          ...state.employmentsStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_SAVE_EMPLOYMENTS:
      return {
        ...state,
        employments: action.employments,
        curEmployments: action.curEmployments,
        prevEmployments: action.prevEmployments,
        workLikeEmployments: action.workLikeEmployments,
        employmentsStatus: {
          ...state.employmentsStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_SAVE_EMPLOYMENTS_ERROR:
      return {
        ...state,
        error: action.error,
        employmentsStatus: {
          ...state.employmentsStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // DELETE Employments
    case ACTIONS.REQUEST_DELETE_EMPLOYMENTS:
      return {
        ...state,
        employmentsStatus: {
          ...state.employmentsStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_DELETE_EMPLOYMENTS:
      return {
        ...state,
        curEmployments: action.curEmployments,
        prevEmployments: action.prevEmployments,
        workLikeEmployments: action.workLikeEmployments,
        employmentsStatus: {
          ...state.employmentsStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_DELETE_EMPLOYMENTS_ERROR:
      return {
        ...state,
        employmentsStatus: {
          ...state.employmentsStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
        error: action.error,
      };

    // GET Workflow
    case REQUEST_START_WORKFLOW:
      return {
        ...state,
        workflowInstanceIdStatus: {
          ...state.workflowInstanceIdStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_START_WORKFLOW:
      return {
        ...state,
        workflowInstanceId: action.workflowInstanceId,
        workflowETag: action.workflowETag,
        workflowInstanceIdStatus: {
          ...state.workflowInstanceIdStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_START_WORKFLOW_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        workflowInstanceIdStatus: {
          ...state.workflowInstanceIdStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Person Info
    case REQUEST_PERSON_INFO:
      return {
        ...state,
        personInfoStatus: {
          ...state.personInfoStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON_INFO:
      return {
        ...state,
        informationETag: action.etag,
        information: action.information,
        personInfoStatus: {
          ...state.personInfoStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_INFO_MISSING:
      return {
        ...state,
        personInfoStatus: {
          ...state.personInfoStatus,
          getStatus: STATUS.MISSING,
        },
      };
    case REQUEST_PERSON_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personInfoStatus: {
          ...state.personInfoStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case REQUEST_PERSON_INFO_RESET:
      return {
        ...state,
        personInfoStatus: {
          ...state.personInfoStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };

    // GET Person META
    case REQUEST_PERSON_META:
      return {
        ...state,
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON_META:
      return {
        ...state,
        meta: action.meta,
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_META_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personMetaStatus: {
          ...state.personMetaStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // PUT Person Info
    case ACTIONS.REQUEST_PUT_PERSON_INFO:
      return {
        ...state,
        personInfoStatus: {
          ...state.personInfoStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_PUT_PERSON_INFO:
      return {
        ...state,
        informationETag: action.etag,
        information: action.information,
        personInfoStatus: {
          ...state.personInfoStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case ACTIONS.REQUEST_PUT_PERSON_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personInfoStatus: {
          ...state.personInfoStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // POST Person Info
    case REQUEST_POST_PERSON_INFO:
      return {
        ...state,
        personInfoStatus: {
          ...state.personInfoStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PERSON_INFO:
      return {
        ...state,
        informationETag: action.etag,
        information: action.information,
        personInfoStatus: {
          ...state.personInfoStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_PERSON_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personInfoStatus: {
          ...state.personInfoStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case MULTISTEP_CREATE_ACCOUNT_START:
      return {
        ...state,
        multistepCreateAccountStatus: {
          ...state.multistepCreateAccountStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case MULTISTEP_CREATE_ACCOUNT_COMPLETION:
      return {
        ...state,
        multistepCreateAccountStatus: {
          ...state.multistepCreateAccountStatus,
          modifyStatus: STATUS.UPDATED,
          errorData: null,
        },
      };
    case MULTISTEP_CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        multistepCreateAccountStatus: {
          ...state.multistepCreateAccountStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // POST Person Generic Info
    case REQUEST_POST_PERSON_GENERIC_INFO:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_POST_PERSON_GENERIC_INFO:
      return {
        ...state,
        genericInfoETag: action.etag,
        genericInfo: action.genericInfo,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_POST_PERSON_GENERIC_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // GET Person Generic Info
    case REQUEST_PERSON_GENERIC_INFO:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PERSON_GENERIC_INFO:
      return {
        ...state,
        genericInfoETag: action.etag,
        genericInfo: action.genericInfo,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PERSON_GENERIC_INFO_MISSING:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.MISSING,
        },
      };
    case REQUEST_PERSON_GENERIC_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    // PUT Person Info
    case REQUEST_PUT_PERSON_GENERIC_INFO:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          modifyStatus: STATUS.UPDATING,
          errorData: null,
        },
      };
    case RECEIVE_PUT_PERSON_GENERIC_INFO:
      return {
        ...state,
        genericInfoETag: action.etag,
        genericInfo: action.information,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          modifyStatus: STATUS.UPDATED,
        },
      };
    case REQUEST_PUT_PERSON_GENERIC_INFO_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          modifyStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_PERSON_GENERIC_INFO_GET_STATUS:
      return {
        ...state,
        personGenericInfoStatus: {
          ...state.personGenericInfoStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };
    default:
      return state;
  }
}

export default person;
