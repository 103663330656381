import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

// ===========================================================
// This component is USED with the 'InTextReference' component
// ===========================================================

export default function Footnotes({
  headingADA,
  footnotes,
}) {
  const classes = styles();
  const [footnoteCount, setFootnoteCount] = useState(0);

  useEffect(() => {
    setFootnoteCount(footnotes.length);
  }, [footnotes.length]);

  return (
    <div className={clsx(classes.fnReferences, 'footnotesWrapper')}>
      <Typography
        variant={headingADA}
        className="sr-only"
        id="footnote-label"
      >
        {`Footnote${footnoteCount > 1 ? 's' : ''}`}
      </Typography>
      {footnoteCount > 1 ? (
        <ol className={classes.footnotes}>
          {footnotes.map((footnote, i) => (
            <Typography
              className={clsx(classes.asterisk, 'ref')}
              component="li"
              id={`footnote${i + 1}`}
              key={i.toString()}
            >
              {footnote.title ? (
                <>
                  <strong>{footnote.title}</strong>
                  <br />
                </>
              ) : null}
              {footnote.text}
              {footnote.list ? (
                <ul className={clsx(classes.footnoteList, 'tight unordered-list')}>
                  {footnote.list.map((item, index) => (
                    <li key={index.toString()}>{item}</li>
                  ))}
                </ul>
              ) : null}
              {' '}
              <a
                href={`#footnoteRef${i + 1}`}
                aria-label={`Return to superscript [${i + 1}]`}
                className={classes.backToContent}
              >
                ↩
              </a>
            </Typography>
          ))}
        </ol>
      ) : (
        <div className={classes.footnotes}>
          <Typography
            className={clsx(classes.asterisk, 'ref one')}
            component="p"
            id="footnote1"
          >
            {footnotes[0]?.title ? (
              <>
                <strong>{footnotes[0]?.title}</strong>
                <br />
              </>
            ) : null}
            {footnotes[0]?.text}
            {footnotes[0]?.list ? (
              <ul className={clsx(classes.footnoteList, 'tight unordered-list')}>
                {footnotes[0]?.list.map((item, index) => (
                  <li key={index.toString()}>{item}</li>
                ))}
              </ul>
            ) : null}
            {' '}
            <a
              href="#footnoteRef1"
              aria-label="Return to superscript [1]"
              className={classes.backToContent}
            >
              ↩
            </a>
          </Typography>
        </div>
      )}
    </div>
  );
}

Footnotes.defaultProps = {
  headingADA: 'h2',
  footnotes: [],
};

Footnotes.propTypes = {
  headingADA: PropTypes.string,
  footnotes: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
  })),
};
