import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  // 2 css properties are located outside of this component but are required,
  // in order for this component to work.
  // counter-reset: footnotes;
  // and
  // counter(footnotes);
  reference: {
    counterIncrement: 'footnotes',
    textDecoration: 'none',
    color: 'inherit',
    outline: 'none',
    scrollMarginTop: theme.spacing(14),
    '&::after': {
      verticalAlign: 'super',
      fontSize: theme.spacing(1),
      marginLeft: '2px',
      color: theme.palette.common.blue,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '&:focus::after': {
      outline: '2px solid',
      outlineOffset: '1px',
    },
  },
  fnReferences: {
    fontSize: theme.spacing(1.2),
    '& :target': {
      background: theme.palette.background.lightBlue,
    },
  },
  footnotes: {
    paddingLeft: theme.spacing(1.7),
    margin: '0 0 2.4rem',
    '& > li': {
      scrollBehavior: 'smooth',
      scrollMarginTop: theme.spacing(10),
      padding: 0,
      margin: '0 0 1.6rem',
    },
    '& > p': {
      scrollBehavior: 'smooth',
      scrollMarginTop: theme.spacing(10),
      padding: 0,
      margin: '0 0 1.6rem',
    },
  },
  backToContent: {
    fontSize: theme.spacing(0.8),
  },
  asterisk: {
    color: theme.palette.text.adaGrey,
    fontSize: 'inherit',
    '&.one': {
      '&::before': {
        content: '"1"',
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(1),
      },
    },
  },
  footnoteList: {
    fontSize: 'inherit!important',
    paddingLeft: theme.spacing(1.7),
    margin: '1rem 0 0',
    [theme.breakpoints.up('sm')]: {
      ' &.columnTwo': {
        columnCount: 2,
      },
    },
    '& li::marker': {
      fontSize: theme.spacing(2.2),
    },
  },
}));
