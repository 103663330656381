const IdentifyVerifyConstants = [
  {
    title: 'How PingOne Verify works',
    stepOneHeader: '1. Use a mobile device',
    paragraphOne: 'Desktop users will receive a QR code to complete the process on mobile.',
    stepTwoHeader: '2. Grab your ID',
    paragraphTwo: 'A current government-issued photo ID is required. You\'ll take a picture of it through the PingOne Verify website.',
    stepThreeHeader: '3. Get selfie ready ',
    paragraphThree: 'This is an optional step to help prevent fraudulent activity.',
    stepFourHeader: '3. Identity verified',
    paragraphFour: 'Once completed, check your application to see if your verification was successful.',
  },
  {
    title: 'How PingOne Verify works',
    stepOneHeader: '1. Use a mobile device',
    paragraphOne: 'Desktop users will receive a QR code to complete the process on mobile.',
    stepTwoHeader: '2. Grab your ID',
    paragraphTwo: 'A current government-issued photo ID (e.g. state ID, driver\'s license or passport) is required. You\'ll take a picture of it through the PingOne Verify website.',
    stepThreeHeader: '3. Get selfie ready ',
    paragraphThree: 'This is an optional step to help prevent fraudulent activity.',
    stepFourHeader: '3. Identity verified',
    paragraphFour: 'Once completed, check your application to see if your verification was successful.',
  },
];

export default IdentifyVerifyConstants;
